<template>
  <div class="container">
    <div class="header">
      <dir class="title-wrapper">
        <div class="title">岱峰阁</div>
        <div class="subtitle">中医药酒按摩</div>
      </dir>
    </div>
    <div class="body">
      <img src="../assets/61662040497_.pic.jpg" class="img-1" />
      <img src="../assets/81662040498_.pic.jpg" class="img-1" />
      <img src="../assets/101662040500_.pic.jpg" class="img-1" />
    </div>
    <div class="footer">
      <a href="https://beian.miit.gov.cn/" class="icp">桂ICP备2022008814号</a>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {

    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  .header {
    width: 100%;
    height: 60px;
    flex-shrink: 0;
    background: tan;
    display: flex;
    padding: 0 50px;
    justify-content: flex-start;
    align-items: center;
    font-size: 0;
    .title-wrapper {
      line-height: 10px;
    }
    .title {
      display: inline-block;
      font-size: 30px;
      font-weight: 600;
      margin-right: 16px;
    }
    .subtitle {
      display: inline-block;
      font-size: 12px;
    }
  }

  .body {
    flex: 1;
    display: flex;
    flex-direction: column;
    .img-1 {
      margin: 0;
      padding: 0;
      width: 100vw;
      height: 100vw;
    }
  }
  .footer {
    flex-shrink: 0;
    width: 100%;
    height: 40px;
    background: tan;
    display: flex;
    justify-content: center;
    align-items: center;
    .icp {
      color: #333333;
    }
  }
}
</style>
